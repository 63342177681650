import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import router from '@/router'
import { format, parse } from 'date-fns'
import { orderBy } from 'lodash'

export const resolveDeadlineVariant = deadline => {
  if (deadline === 4) return 'success'
  if (deadline === 3) return 'info'
  if (deadline === 2) return 'warning'
  return 'danger'
}

export const resolveStatusVariant = deadline => {
  if (deadline === 'synchronized') return 'info'
  if (deadline === 'pending') return 'warning'
  return 'success'
}

export default function usePaymentRemittanceView() {
  // Use toast
  const toast = useToast()

  const refListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    {
      key: 'actions',
      label: 'Ações',
      sortable: false,
    },
    {
      key: 'signed_at',
      label: 'Data Solicitação',
      sortable: false,
    },
    {
      key: 'contract_code',
      label: 'Contrato',
      sortable: false,
    },
    {
      key: 'name',
      label: 'Nome',
      sortable: true,
    },
    {
      key: 'company',
      label: 'Empresa',
      sortable: false,
    },
    {
      key: 'cpf',
      label: 'CPF',
      sortable: false,
    },
    {
      key: 'status_title',
      label: 'STATUS',
      sortable: true,
    },
    {
      key: 'deadline',
      label: 'PRAZO PAGAMENTO',
      sortable: false,
    },
    {
      key: 'workplace_duty_type',
      label: 'Serviço',
      sortable: false,
    },
    {
      key: 'start_date',
      label: 'Data Plantão',
      sortable: false,
    },
    {
      key: 'amount_without_discount',
      label: 'Valor serviço',
      sortable: false,
    },
    {
      key: 'rate_value',
      label: 'Taxa',
      sortable: false,
    },
    {
      key: 'amount_with_discount',
      label: 'Valor pagamento',
      sortable: false,
    },
    {
      key: 'bank_name',
      label: 'Banco',
      sortable: false,
    },
    {
      key: 'account_type',
      label: 'Tipo Conta',
      sortable: false,
    },
    {
      key: 'branch_number',
      label: 'Agência',
      sortable: false,
    },
    {
      key: 'account_number_and_code',
      label: 'Conta',
      sortable: false,
    },
    {
      key: 'operation',
      label: 'Código operação',
      sortable: false,
    },
    {
      key: 'payment_date',
      label: 'Data de pagamento',
      sortable: false,
    },
    {
      key: 'institution',
      label: 'Instituição',
      sortable: false,
    },
    {
      key: 'workplace',
      label: 'Unidade',
      sortable: false,
    },

  ]
  const perPage = ref(25)
  const totalRemittances = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('name')
  const isSortDirDesc = ref(false)
  const situationFilter = ref(null)
  const statusFilter = ref(null)
  const contractList = ref([])

  const paymentRemittance = ref(null)
  const paymentRemittanceItem = ref(null)
  const paymentRemittanceItemData = ref({})

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalRemittances.value,
    }
  })

  const refetchData = () => {
    refListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, situationFilter, statusFilter], () => {
    refetchData()
  })

  const getPaymentRemittance = () => store.dispatch('app-contract/fetchPaymentRemittance', { id: router.currentRoute.params.id })
    .then(response => {
      paymentRemittance.value = response.data.data
    })
    .catch(() => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Erro ao buscar ',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    })

  const sortItems = items => orderBy(items, [sortBy.value], [isSortDirDesc.value ? 'desc' : 'asc'])

  const fetchPaymentRemittance = (ctx, callback) => {
    if (!paymentRemittance.value) {
      getPaymentRemittance().then(() => {
        callback(sortItems(paymentRemittance.value.items))
        totalRemittances.value = paymentRemittance.value.items.length
      })
    } else {
      callback(sortItems(paymentRemittance.value.items))
      totalRemittances.value = paymentRemittance.value.items.length
    }
  }

  const resetItem = item => {
    let date = ''
    if (item.value?.payment_date) {
      date = format(new Date(item.value?.payment_date), 'dd/MM/yyyy HH:mm')
    }
    paymentRemittanceItemData.value = {
      item_id: item.value?.id || '',
      payment_date: date,
      operation_code: item.value?.operation || '',
    }
  }

  const updatePaymentRemittanceItem = async () => {
    const items = contractList.value.filter(contract => contract.checked).map(contract => ({ contract_id: contract.id, operation: null }))
    try {
      const { data } = await store
        .dispatch('app-contract/createPaymentRemittance', {
          items,
        })
      return {
        id: data.data.id,
        code: data.data.code,
      }
    } catch (err) {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Erro',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    }
    return null
  }

  const saveItem = () => {
    const item = {
      item_id: paymentRemittanceItemData.value.item_id,
      payment_date: paymentRemittanceItemData.value.payment_date,
      operation_code: paymentRemittanceItemData.value.operation_code,
    }
    item.payment_date = format(parse(item.payment_date, 'dd/MM/yyyy HH:mm', new Date()), 'yyyy-MM-dd HH:mm:ss')
    store.dispatch('app-contract/updatePaymentRemittance', item)
      .then(() => {
        refetchData()
        toast({
          component: ToastificationContent,
          props: {
            title: 'Atualizado!',
            icon: 'CheckIcon',
            variant: 'success',
            text: '',
          },
        })
      })
      .catch(error => {
        if (error.response.status === 404) {
          //
        }
      })
  }

  async function removeItem() {
    const result = await this.$swal({
      title: 'Você tem certeza?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim, remover!',
      cancelButtonText: 'Não',
      customClass: {
        confirmButton: 'btn btn-danger',
        cancelButton: 'btn btn-outline-primary ml-1',
      },
      buttonsStyling: false,
    })
    if (result.value) {
      store.dispatch('app-contract/deletePaymentRemittance', { id: paymentRemittanceItem.value.id })
        .then(() => {
          refetchData()
        })
    }
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUserRoleVariant = role => {
    if (role === 'subscriber') return 'primary'
    if (role === 'author') return 'warning'
    if (role === 'maintainer') return 'success'
    if (role === 'editor') return 'info'
    if (role === 'admin') return 'danger'
    return 'primary'
  }

  const resolveUserRoleIcon = role => {
    if (role === 'subscriber') return 'UserIcon'
    if (role === 'author') return 'SettingsIcon'
    if (role === 'maintainer') return 'DatabaseIcon'
    if (role === 'editor') return 'Edit2Icon'
    if (role === 'admin') return 'ServerIcon'
    return 'UserIcon'
  }

  return {
    getPaymentRemittance,
    fetchPaymentRemittance,
    tableColumns,
    perPage,
    currentPage,
    totalUsers: totalRemittances,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refUserListTable: refListTable,

    resolveUserRoleVariant,
    resolveUserRoleIcon,
    resolveDeadlineVariant,
    resolveStatusVariant,
    refetchData,

    // Extra Filters
    situationFilter,
    statusFilter,
    updatePaymentRemittanceItem,
    paymentRemittance,
    paymentRemittanceItem,
    paymentRemittanceItemData,
    resetItem,
    removeItem,
    saveItem,
  }
}
